// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import { cssBundleHref } from "@remix-run/css-bundle";
import mainCss from "../public/assets/css/main.css";
import tailwind from "~/tailwind.css";
import "bootstrap/dist/css/bootstrap.min.css?__remix_sideEffect__";
import "@fortawesome/fontawesome-svg-core/styles.css?__remix_sideEffect__";
import { config } from "@fortawesome/fontawesome-svg-core";
import Hotjar from "@hotjar/browser";
config.autoAddCss = false;
import { Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration } from "@remix-run/react";
import { NextUIProvider } from "@nextui-org/react";
import { useEffect } from "react";
import { getUser } from "./services/session.server";
export const links = () => [...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : []), {
  rel: "stylesheet",
  href: mainCss
}, {
  rel: "stylesheet",
  href: tailwind
}];
export const meta = () => {
  return [{
    name: "viewport",
    content: "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
  }, {
    title: "New Remix App"
  }];
};
export const loader = async ({
  request
}) => {
  // Add logic here to fetch user data
  var userId = "";
  const user = await getUser(request); // Example function to fetch user data
  if (user) {
    userId = user.id;
  } else {
    userId = "Not logged in";
  }
  return {
    userId
  };
};
async function reportError(userId, error, currentDate) {
  try {
    const response = await fetch("api/error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        userId: userId,
        time: currentDate,
        error: error
      }),
      credentials: "include"
    });
    const result = await response.json();
    if (result.error) {
      throw new Error("Network response was not ok");
    }
  } catch (error) {
    // Handle errors here
    console.error("There was a problem with the fetch operation:", error);
    // You can also throw the error again if you want to propagate it further
    throw error;
  }
}
function copyText(text, date, userId) {
  // Create a temporary textarea element
  var textarea = document.createElement("textarea");
  textarea.value = text + date + userId;

  // Append the textarea to the body
  document.body.appendChild(textarea);

  // Select the text inside the textarea
  textarea.select();

  // Copy the text inside the textarea to the clipboard
  navigator.clipboard.writeText(textarea.value);

  // Alert the copied text
  alert("Copied the text: " + textarea.value);

  // Remove the textarea from the DOM
  document.body.removeChild(textarea);
}

/*export function ErrorBoundary() {
  //const {userId} = useLoaderData<typeof loader>();
  

  return (
    <html>
      <head>
        <title>Oh no!</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#ffcccc", // Mostly red
            padding: "20px",
            border: "2px solid #ff0000", // Red
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faBug}
            style={{ color: "#ff0000", fontSize: "36px", marginBottom: "10px" }}
          />
          <p>Uh Oh! Something's gone wrong!</p>
          
          
          <button className="rounded-md p-1 border-2 border-black hover:bg-red-300">
            <a href="/">Go Home</a>
          </button>
        </div>
        <Scripts />
      </body>
    </html>
  );
}*/
export default function App() {
  _s();
  useEffect(() => {
    const siteId = 4945720;
    const hotjarVersion = 6;
    Hotjar.init(siteId, hotjarVersion);
  }, []);
  return <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1 maximum-scale=1.0, user-scalable=0" />
        <Meta />
        <Links />
      </head>
      <body>
        <NextUIProvider>
          <Outlet />
          <ScrollRestoration />
          <Scripts />
          <LiveReload />
        </NextUIProvider>
      </body>
    </html>;
}
_s(App, "OD7bBpZva5O2jO+Puf00hKivP7c=");
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;